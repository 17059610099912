import script from "./OrganizationDetails.vue?vue&type=script&setup=true&lang=ts"
export * from "./OrganizationDetails.vue?vue&type=script&setup=true&lang=ts"

import "./OrganizationDetails.vue?vue&type=style&index=0&id=2599b854&lang=scss"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QTabPanels,QTabPanel,QExpansionItem,QCard,QTable,QColor});
