import { i18n } from "@/main";
const t = i18n.global.t;
export default class LocationsTableData {
    static getColumns() {
        return [
            {
                name: "deviceName",
                align: "center",
                label: t("shared.deviceName"),
                field: "deviceName",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "device-imei",
                align: "center",
                label: t("device.imei"),
                field: "device",
                format: (val) => `${val.imei ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "location",
                align: "center",
                label: t("device.locationName"),
                field: "location",
                format: (val) => `${val?.name ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "team",
                align: "center",
                label: t("device.teamName"),
                field: "team",
                format: (val) => `${val?.name ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "department",
                align: "center",
                label: t("device.department"),
                field: "deviceDepartment",
                format: (val) => `${val?.name ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "mobility-class",
                align: "center",
                label: t("device.mobilityClass"),
                field: "mobilityClass",
                format: (val) => `${val?.name ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "isActive",
                align: "center",
                label: t("shared.isActive"),
                field: "isActive",
                format: (val) => `${val ? t("shared.active") : t("shared.inactive")}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-details",
                align: "left",
                label: "",
                field: "action-details",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
        ];
    }
}
