import script from "./AddLocation.vue?vue&type=script&setup=true&lang=ts"
export * from "./AddLocation.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QForm,QItem,QItemSection,QItemLabel,QToggle,QInput,QSelect,QCardActions,QBtn});
